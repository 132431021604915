import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 400 400">
    <title>Logo</title>
    <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
    fill="#64ffda" stroke="none">
      <path d="M1804 3845 c-332 -43 -646 -174 -909 -380 -119 -93 -290 -275 -374
      -398 -315 -465 -394 -1032 -217 -1562 126 -374 410 -731 752 -943 665 -413
      1514 -368 2129 113 119 93 290 275 373 398 140 205 244 460 288 702 25 140 25
      450 0 590 -43 240 -149 498 -287 702 -84 123 -255 305 -374 398 -389 304 -892
      443 -1381 380z m416 -176 c401 -49 739 -216 1011 -495 550 -567 599 -1425 118
      -2063 -72 -96 -240 -258 -345 -333 -542 -387 -1274 -409 -1852 -57 -389 238
      -662 645 -744 1109 -17 97 -17 393 0 490 93 525 409 947 893 1191 145 72 357
      135 539 158 95 12 283 13 380 0z"
      fill="#64ffda"
      />
      <path d="M1495 3019 c146 -51 307 -138 404 -219 l56 -47 101 -5 c119 -7 173
      -27 221 -81 40 -46 57 -105 50 -182 -5 -68 -26 -117 -68 -156 -27 -25 -28 -28
      -16 -65 6 -22 12 -101 12 -176 0 -130 1 -137 21 -142 35 -9 111 -83 128 -126
      59 -147 4 -303 -126 -360 -45 -20 -74 -24 -192 -28 l-139 -4 -52 -42 c-100
      -82 -267 -170 -415 -220 l-75 -25 390 0 c491 -1 569 9 741 94 151 74 245 192
      279 350 35 166 -1 331 -96 440 -46 52 -138 110 -199 126 -28 7 -50 16 -50 19
      0 3 16 14 36 23 20 10 60 40 89 68 94 89 136 225 116 375 -22 153 -103 263
      -244 329 -133 63 -167 67 -622 71 l-410 4 60 -21z"
      fill="#64ffda"
      />
      <path d="M1385 3005 l-25 -24 0 -889 c0 -863 1 -890 19 -913 l20 -24 83 28
      c142 49 287 130 388 216 l35 30 -82 1 -83 0 0 275 0 275 225 0 225 0 0 108 c0
      59 -3 123 -6 142 l-6 35 -219 -3 -219 -3 0 246 0 245 88 0 87 1 -40 35 c-92
      82 -241 167 -383 218 -40 14 -75 26 -78 26 -3 0 -16 -11 -29 -25z"
      fill="#8892b0"
      />
    </g>
  </svg>
);

export default IconLogo;
